import React from 'react';
import { Input, Switch, Button, Row, Col } from 'antd';

class AppSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iosVersion: '',
            maintenance: false,
            iosForceUpdate: false,
            androidVersion: '',
            androidForceUpdate: false
        };
    }

    handleInputChange = (key, value) => {
        this.setState({ [key]: value });
    };

    handleSubmit = () => {
        // Handle submit logic here
        // You can access the state values using this.state
        console.log('Submitted:', this.state);
    };

    render() {
        const { iosVersion, maintenance, iosForceUpdate, androidVersion, androidForceUpdate } = this.state;

        return (
            <div>
                <h1>App Settings</h1>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Input
                            placeholder="IOS version"
                            value={iosVersion}
                            onChange={e => this.handleInputChange('iosVersion', e.target.value)}
                        />
                    </Col>
                    <Col span={12}>
                        <Input
                            placeholder="Android version"
                            value={androidVersion}
                            onChange={e => this.handleInputChange('androidVersion', e.target.value)}
                        />
                    </Col>
                    <Col span={12}>
                        <Switch
                            checked={maintenance}
                            onChange={val => this.handleInputChange('maintenance', val)}
                        />
                        Maintainence
                    </Col>
                    <Col span={12}>
                        <Switch
                            checked={iosForceUpdate}
                            onChange={val => this.handleInputChange('iosForceUpdate', val)}
                        />
                        IOS Force Update
                    </Col>
                    
                    <Col span={12}>
                        <Switch
                            checked={androidForceUpdate}
                            onChange={val => this.handleInputChange('androidForceUpdate', val)}
                        />
                        Android Force Update
                    </Col>
                    <Col span={24}>
                        <Button type="primary" onClick={this.handleSubmit}>Submit</Button>
                        <Button style={{ marginLeft: '10px' }} onClick={() => {}}>Cancel</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AppSettings;
