import React, { useState, useEffect } from "react";
import { Select, Button, Row, Col, message } from "antd";
import { REACT_APP_ApiUrl } from "../../config/config";
import axios from "axios";
const { Option } = Select;
const BirthdaySettings = () => {
  const [selectedBirthday, setSelectedBirthday] = useState(null);
  const [loading, setLoading] = useState(false);
  console.log('helo',window.localStorage.getItem("token"))
  // Fetch the existing birthday frequency
  useEffect(() => {
    const fetchBirthdayFrequency = async () => {
      try {
        const response = await axios.post(
          `${REACT_APP_ApiUrl}/api/get-birthday-frequency`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        );
    
        console.log("dataaaa", response.data);
    
        if (response.data.status && response.data.data.frequency) {
          setSelectedBirthday(response.data.data.frequency);
        }
      } catch (error) {
        console.error("Error fetching birthday frequency:", error);
      }
    };
    fetchBirthdayFrequency();
  }, []);

  // Handle Select Change
  const handleSelectChange = (value) => {
    setSelectedBirthday(value);
  };

  // Handle Form Submission
  const handleSubmit = async () => {
    if (!selectedBirthday) {
      message.error("Please select a birthday notification frequency!");
      return;
    }
  
    setLoading(true);
    try {
      const response = await axios.post(
        `${REACT_APP_ApiUrl}/api/update-birthday-frequency`,
        { frequency: selectedBirthday },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`, // Ensure token is included
          },
        }
      );
  
      console.log("API Response:", response.data);
  
      if (response.data.status) {
        message.success("Birthday frequency updated successfully!");
      } else {
        message.error("Failed to update birthday frequency.");
      }
    } catch (error) {
      message.error("Error updating birthday frequency.");
      console.error("Error:", error.response?.data || error.message);
    }
    setLoading(false);
  };
  

  return (
    <div>
      <h1>Birthday Reminder Settings</h1>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Select
            placeholder="Select a birthday notification frequency"
            style={{ width: "100%" }}
            value={selectedBirthday}
            onChange={handleSelectChange}
          >
            <Option value="Day of Event">Day of Birthday</Option>
            <Option value="Day before Event">Day before Birthday</Option>
            <Option value="2 Days before Event">2 Days before Birthday</Option>
            <Option value="1 Week before Event">1 Week before Birthday</Option>
            <Option value="2 Weeks before Event">2 Weeks before Birthday</Option>
          </Select>
        </Col>
        <Col span={24}>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            Submit
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            onClick={() => setSelectedBirthday(null)}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default BirthdaySettings;
