import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { postSweetTips } from '../../services/api';
import { message } from 'antd';

class SweetTips extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: '',
            answers: '',
            userId:'63d8f5771a02db7966635531'
        };
    }

    handleInputChange = (key, value) => {
        this.setState({ [key]: value });
    };

    handleSubmit = () => {
        postSweetTips(this.state)
            .then(() => {
                message.success('Sweet Tip saved successfully');
                // Optionally, you can reset the form fields after successful submission
                this.setState({ questions: '', answers: '' });
            })
            .catch((error) => {
                message.error('Failed to save Sweet Tip');
            });
    };
    

    render() {
        const { questions, answers } = this.state;

        return (
            <div>
                <h1>Sweet Tips</h1>
                <Form layout="vertical" onFinish={this.handleSubmit}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item label="Question" name="questions" initialValue={questions}>
                                <Input
                                    placeholder="Enter your question"
                                    onChange={e => this.handleInputChange('questions', e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Answer" name="answers" initialValue={answers}>
                                <Input.TextArea
                                    rows={4}
                                    placeholder="Enter your answer"
                                    onChange={e => this.handleInputChange('answers', e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Save</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default SweetTips;
