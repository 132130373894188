import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Popconfirm, Row, Col, Card, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { REACT_APP_ApiUrl } from '../../config/config';
import Moment from 'react-moment';

const { Search } = Input;
const { Text } = Typography;

const AllSweetTips = () => {
    const [listData, setListData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [limit, setLimit] = useState(25);
    const [current, setCurrent] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [current, limit, searchText]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${REACT_APP_ApiUrl}/api/get-all-admin-sweet-tips`, {
                page: Math.max(1, current),
                limit,
                searchText,
            });
            setListData(response.data?.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    };

    const deleteTip = async (id) => {
        try {
            await axios.delete(`${REACT_APP_ApiUrl}/api/delete-sweet-tip`, { params: { _id: id } });
            fetchData();
        } catch (error) {
            console.error('Error deleting sweet tip:', error);
        }
    };

    const columns = [
        {
            title: <strong>Question</strong>,
            dataIndex: 'questions',
            key: 'questions',
        },
        {
            title: <strong>Answer</strong>,
            dataIndex: 'answers',
            key: 'answers',
        },
        {
            title: <strong>Created Date</strong>,
            dataIndex: 'created_at',
            key: 'created_at',
            render: (val) => (val ? <Moment format="MM-DD-YYYY HH:mm">{val}</Moment> : '-')
        },
        {
            title: <strong>Action</strong>,
            key: 'action',
            width: 100,
            align: 'center',
            render: (_, record) => (
                <Popconfirm title="Are you sure?" onConfirm={() => deleteTip(record._id)} okText="Yes" cancelText="No">
                    <Button type="danger"><DeleteOutlined /></Button>
                </Popconfirm>
            )
        }
    ];

    return (
        <div>
            <Row className="TopActionBar" gutter={[16, 0]} justify="space-between" align="middle">
                <Col>
                    <Search placeholder="Search..." onChange={(e) => setSearchText(e.target.value)} value={searchText} loading={loading} />
                </Col>
            </Row>

            <div className="innerContainer">
                <Card>
                    <Table
                        columns={columns}
                        dataSource={listData}
                        rowKey="_id"
                        pagination={{
                            current,
                            pageSize: limit,
                            showSizeChanger: true,
                            onChange: setCurrent,
                            onShowSizeChange: (_, size) => setLimit(size)
                        }}
                        loading={loading}
                    />
                </Card>
            </div>
        </div>
    );
};

export default AllSweetTips;